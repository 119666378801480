import { PageProps } from "gatsby";
import React from "react";

import { About } from "../components/pages/About";

import { snProps } from "../js/utils";

const AboutPage = (props: PageProps) => <About {...{ ...snProps, ...props }} />;

export default AboutPage;
